import React, { FC, ReactNode } from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";

// Shared UI Library Components
import { CustomIcon, EIconName, TElementMode } from '@ppmcore/seven-ppm-core-shared-components-react';

// Components
import { PaymentForm } from '../../shared/payment-components';
import { FormProvider } from "../../shared/paypal/form-provider/form-provider";

// Styles
import './checkout-modal.scss';

// Store entities
import { getUserState } from "../../store/user/user.selectors";

// Models
import { TCallType, TRateType } from '../../interfaces/call.interfaces';
import { ICurrency } from '../../interfaces/company.interfaces';

export interface ICheckoutModalProps {
  open: boolean;
  currency: ICurrency;
  company_account_id: string | null;
  consultation_type: TCallType;
  worker_id: string | number;
  call_duration?: string | number;
  mode?: TElementMode;
  rate?: number;
  rate_type?: TRateType;
  btn_text?: string;
  children: React.ReactNode;
  onCancel?: () => void;
  onPaid?: (pi: string) => void;
}

export const CheckoutModal = (
  {
    open,
    currency,
    company_account_id,
    consultation_type,
    worker_id,
    call_duration,
    rate = 0,
    rate_type = 'pay_per_minute',
    mode = 'white',
    btn_text = '',
    children,
    onCancel = () => {},
    onPaid = () => {}
  }: ICheckoutModalProps
) => {
  const { user } = useSelector(getUserState);

  return (
    <Modal wrapClassName={ `checkout-modal checkout-modal-${ mode }` }
           destroyOnClose={ true }
           centered={ true }
           title="Checkout"
           closeIcon={ <CustomIcon name={ EIconName.Close }/> }
           footer={ null }
           onCancel={ onCancel }
           open={ open }>
      <div className="checkout-modal--body">
        <div className="body-item body-item--info body-item--divider">
          { children }
        </div>
        <div className="body-item body-item--payment-form">
          { user?.payment_provider === 'stripe' && <PaymentForm consultation_type={ consultation_type }
                         rate={ rate }
                         rate_type={ rate_type }
                         call_duration={ call_duration }
                         currency={ currency }
                         btn_text={ btn_text }
                         worker_id={ worker_id }
                         onPaid={ onPaid }
                         mode={ mode }/> }

          { user?.payment_provider === 'paypal' && <FormProvider consultation_type={ consultation_type }
                         rate={ rate }
                         rate_type={ rate_type }
                         call_duration={ call_duration }
                         currency={ currency }
                         btn_text={ btn_text }
                         worker_id={ worker_id }
                         onPaid={ onPaid }
                         mode={ mode }/> }
        </div>
      </div>
    </Modal>
  );
};

CheckoutModal.propTypes = {
  children: PropTypes.node.isRequired
};
